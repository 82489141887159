import React, { useEffect } from 'react'
import Buttons from './Buttons'
import './Button_cnt.css'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FetchCustomerdata, setPage } from '../../Redux/Slices/CustomerSlice'
import AuthService from '../../Authservice'


const Button_container = () => {
    const dispatch = useDispatch();
    const { customerpagination } = useSelector((state) => state.customer);
    useEffect(() => {
        const Currenttoken = AuthService.decryptData('JWT');
      //  console.log(Currenttoken)
        if (Currenttoken) {
            dispatch(FetchCustomerdata());
        }
    }, [customerpagination.CustomerStatus]);
    const vrnstatus = () => {
        dispatch(setPage({ CustomerStatus: "0" }));
    }
    return (
        <div className='button_cnt_in'>
            <p>VRN</p>
            <div className='roww'>
                <div className=' btn_in_prp'>
                    <Link to="/VRN_dashboard">
                        <Buttons
                            innertxt={"VRN Dashboard"}
                            innerclr={"03a2d6"}
                        />
                    </Link>
                </div>
                <div className=' btn_in_prp'>
                    <Link onClick={vrnstatus} to="/VRN_master">
                        <Buttons
                            innertxt={"VRN Master"}
                            innerclr={"ff4fff"}
                        />
                    </Link>
                </div>
                <div className='btn_in_prp'>
                    <Link to="/User_master">
                        <Buttons
                            innertxt={"User Master"}
                            innerclr={"b3234b"}
                        />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Button_container