import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./Slices/UserSlice";
import customerReducer from "./Slices/CustomerSlice";
import customermainReducer from "./Slices/Customermain"

const store = configureStore({
    reducer: {
        user: userReducer,
        customer: customerReducer,
        customermain: customermainReducer
    },
});

export default store;