import React, { useEffect } from 'react'
import RedirectLoader from './RedirectLoader';
import { useState } from 'react';
import AuthService from '../Authservice';
import { useNavigate } from 'react-router-dom';

const SSO_CallBack = () => {

    const [redirecting, setRedirecting] = useState(false);

    useEffect(() => {
        localStorage.clear();
    }, []);

    useEffect(() => {
        const url = new URL(window.location.href);
        const randValue = url.searchParams.get('rand') || url.searchParams.get('code');
        const decodedRandValue = randValue ? decodeURIComponent(randValue) : null;
        console.log('Rand value:', decodedRandValue);
        GetLoginDetails(decodedRandValue);
        setRedirecting(true);
    }, []);
    const navigate = useNavigate();
    const encryptData = (name, data) => {
        localStorage.setItem(name, data);
    };

    const GetLoginDetails = (code) => {
        // console.log(code, 'method')
        AuthService.GetServiceCall('api/Login/SSOLogin?code=' + code)
            .then((res) => {
                if (res && res !== undefined && res !== null) {
                    console.log(res)
                  //  encryptData('useridentifier', res.identifier)
                    if (res.isAuthorized === true) {
                        // setLoginErr(prevState => ({
                        //     ...prevState,
                        //     loginerr: false
                        // }));
                       // encryptData('Usertype', res.userType)
                        encryptData('JWT', res.authToken)
                        navigate('/VRN_dashboard')
                    } else {
                        // setLoginErr(prevState => ({
                        //     ...prevState,
                        //     loginerr: true
                        // }));
                        navigate('/Unauthorized')
                    }
                }
            }).catch((err) => {
                // console.log("Error:", err);
                // navigate('/unauthorized')
            });
    };


    return (
        <div className='container'>
            {redirecting && redirecting ? (
                <RedirectLoader />
            ) : (
                <div className='container m-5 p-5 frm_bg'>Loader...</div>
            )}
        </div>
    )
}

export default SSO_CallBack