// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hamberger_sym {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: end;
    background: #000;
    padding: 8px 8px;
    border-radius: 50%;
    cursor: pointer;
}
.header_top {
    background-color: #000;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 5px 5px;
    align-items: center;
}
.header_left_sd {
    display: flex;
    align-items: center;
    gap: 40px;
}
span.line_1 {
    width: 22px;
    height: 2px;
    background: #fff;
    display: block;
}
span.line_2 {
    width: 15px;
    height: 2px;
    background: #fff;
    display: block;
}
.mbfs_image img {
    width: 250px;
}
.e_invoice_head p {
    margin-bottom: 0;
    font-size: 15px;
    color: #fff;
}
.end_logout_btn button {
    border: none;
    background-color: #fff;
    font-size: 14px;
    padding: 5px 12px 2px 12px;
    border-radius: 3px;
}
.end_logout_btn i {
    margin-right: 6px;
}`, "",{"version":3,"sources":["webpack://./src/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,sBAAsB;IACtB,aAAa;IACb,8BAA8B;IAC9B,yBAAyB;IACzB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,WAAW;AACf;AACA;IACI,YAAY;IACZ,sBAAsB;IACtB,eAAe;IACf,0BAA0B;IAC1B,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".hamberger_sym {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n    align-items: end;\n    background: #000;\n    padding: 8px 8px;\n    border-radius: 50%;\n    cursor: pointer;\n}\n.header_top {\n    background-color: #000;\n    display: flex;\n    justify-content: space-between;\n    padding: 5px 10px 5px 5px;\n    align-items: center;\n}\n.header_left_sd {\n    display: flex;\n    align-items: center;\n    gap: 40px;\n}\nspan.line_1 {\n    width: 22px;\n    height: 2px;\n    background: #fff;\n    display: block;\n}\nspan.line_2 {\n    width: 15px;\n    height: 2px;\n    background: #fff;\n    display: block;\n}\n.mbfs_image img {\n    width: 250px;\n}\n.e_invoice_head p {\n    margin-bottom: 0;\n    font-size: 15px;\n    color: #fff;\n}\n.end_logout_btn button {\n    border: none;\n    background-color: #fff;\n    font-size: 14px;\n    padding: 5px 12px 2px 12px;\n    border-radius: 3px;\n}\n.end_logout_btn i {\n    margin-right: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
