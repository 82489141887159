import React, { useState } from 'react'
import Header from './Header/Header'
import Main_content from './Main_content/Main_content'
import Page_not_found from './Page_not_found'

const Home = () => {
    const [toggle, setToggle] = useState(true)
    const [pagenotfound, setpagenotfound] = useState(false)
    return (
        <div>
            <div className='container-fluid ps-0 pe-0'>
                {
                    !pagenotfound &&
                    <>
                        <Header
                            toggle={toggle}
                            setToggle={setToggle}
                        />
                        <Main_content
                            toggle={toggle}
                            setpagenotfound={setpagenotfound}
                        />
                    </>
                }
                {
                    pagenotfound && (
                        <Page_not_found />
                    )
                }
            </div>
        </div>
    )
}

export default Home