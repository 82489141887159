import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../../Authservice";
export const FetchUserdata = createAsyncThunk('fetchCompanyDropdown', async (_, { getState, rejectvalue }) => {

    try {
        const { userPaginations } = getState().user;
        const Usermaster = {
            startPage: userPaginations.Current_page,
            noofPages: 0,
            sortType: true,
            sortColumn: '',
            search: userPaginations.search,
            userstatus: userPaginations.userstatus,
            usertype: userPaginations.usertype,
        };
        const response = await AuthService.PostServiceCallToken('api/User/GetUserDetails', Usermaster);
        return { data: response.data, recordCount: response.recordCount };
    } catch (error) {
        return rejectvalue(error);
    }
});

const initialState = {
    usermasterData: [],
    usermasterDataCount: 0,
    userPaginations: {
        Current_page: 1,
        noofPages: 0,
        sortType: true,
        sortColumn: '',
        search: '',
        userstatus: "0",
        usertype: 0,
    },
    loading: false,
    error: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.userPaginations.Current_page = action.payload.Current_page || state.userPaginations.Current_page;
            state.userPaginations.userstatus = action.payload.userstatus || state.userPaginations.userstatus;
            state.userPaginations.usertype = action.payload.usertype || state.userPaginations.usertype;
            state.userPaginations.search = action.payload.search !== undefined ? action.payload.search : state.userPaginations.search;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(FetchUserdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(FetchUserdata.fulfilled, (state, action) => {
                state.loading = false;
                state.usermasterData = action.payload.data;
                state.usermasterDataCount = action.payload.recordCount;
            })
            .addCase(FetchUserdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.usermasterData = [];
            });
    },
});

export const { setPage } = userSlice.actions;
export default userSlice.reducer;