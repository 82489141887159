import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FetchUserdata, setPage } from '../../Redux/Slices/UserSlice';
import AuthService from '../../Authservice';
import Pagination from './Pagination';
const User_master = () => {

  const dispatch = useDispatch();
  const { usermasterData, usermasterDataCount, userPaginations, loading, error } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(FetchUserdata());
  }, [dispatch, userPaginations.Current_page, userPaginations.userstatus, userPaginations.usertype, userPaginations.search]);


  const [UserUpdate, setUserUpdate] = useState({ userName: '', emailId: '', department: '', userStatus: '2', userType: 1, password: '', identifier: '' })
  const [UserUpdateerr, setUserUpdateerr] = useState({ userName: false, emailId: false, department: false, password: false })
  const [Userdelete, Setuserdelete] = useState('')
  const handle_view_change = (e) => {
    const { name, value } = e.target
    setUserUpdate({ ...UserUpdate, [name]: value })
  };
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  const Userdetailssubmit = () => {
    const isUserNameValid = UserUpdate.userName !== '';
    setUserUpdateerr(state => ({ ...state, userName: !isUserNameValid }));

    const isEmailValid = UserUpdate.emailId !== '' && validateEmail(UserUpdate.emailId);
    setUserUpdateerr(state => ({ ...state, emailId: !isEmailValid }));

    const isDepartmentValid = UserUpdate.department !== '';
    setUserUpdateerr(state => ({ ...state, department: !isDepartmentValid }));

    if (isUserNameValid && isEmailValid && isDepartmentValid) {
      AuthService.PostServiceCallToken('api/User/InsertUpdateData', UserUpdate)
        .then((res) => {
          console.log(res)
          if (res.status === "success") {
            cancelusersumbit();
            dispatch(FetchUserdata());
            document.getElementById('model_cancel').click();
          }
        })
    }
  }
  const cancelusersumbit = () => {
    setUserUpdate(state => ({
      ...state, userName: '', emailId: '', department: '', password: '', identifier: ''
    }))
    setUserUpdateerr(prev => ({ ...prev, password: false, department: false, emailId: false, userName: false }))
  }
  const Editusermaster = (userName, emailId, department, userStatus, userType, password, identifier) => {
    setUserUpdate(state => ({
      ...state, userName: userName, emailId: emailId, department: department, userStatus: userStatus, userType: userType, password: password, identifier: identifier
    }))
    document.getElementById('add_userdata').click();
  }
  // console.log(usermasterData)
  const Userdeltemodel = (identifier) => {
    Setuserdelete(identifier)
    document.getElementById('delete_model').click();
  }
  const UserDelete = () => {
    AuthService.PostServiceCallToken(`api/User/DeleteData?identifier=${Userdelete}`)
      .then((res) => {
        console.log(res)
        if (res.status === "success") {
          Setuserdelete('')
          dispatch(FetchUserdata());
        }
      })
  }
  const handleselectCustomerstatus = (e) => {
    console.log(e.target.value)
    dispatch(setPage({ userstatus: e.target.value }));
  }
  const handleselectusertype = (e) => {
    console.log(e.target.value)
    dispatch(setPage({ usertype: e.target.value }));
  }
  const [usersearch, setusersearch] = useState('')
  const handlesearchusersearch = () => {
    dispatch(setPage({ search: usersearch }));
  }
  const usermasterNumbers = [];
  for (let i = 1; i <= Math.ceil(usermasterDataCount && usermasterDataCount / 10); i++) {
    usermasterNumbers.push(i);
  }
  const UserhandlePageChange = (pageNumber) => {
    dispatch(setPage({ Current_page: pageNumber }));
  };
  return (
    <div>
      <input type="text" hidden id='delete_model' data-bs-toggle="modal" data-bs-target="#delete_hit" />
      <div className="modal fade" id="profile_hit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Add User</h5>
              <button type="button" className="btn-close" onClick={cancelusersumbit} data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <label className="label_w_s mt-2" htmlFor="a_c_i_pan">Name</label>
              <input className="search_inp_filed id in_tb" onChange={handle_view_change} value={UserUpdate.userName} type="text" name="userName" placeholder="" />
              {UserUpdateerr.userName === true &&
                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
              }
              <label className="label_w_s mt-2" htmlFor="a_c_i_pan">Email</label>
              <input className="search_inp_filed in_tb" type="text" onChange={handle_view_change} name="emailId" value={UserUpdate.emailId} placeholder="" />
              {UserUpdateerr.emailId === true &&
                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
              }
              <label className="label_w_s mt-2" htmlFor="a_c_i_pan">Department</label>
              <input className="search_inp_filed in_tb" type="text" onChange={handle_view_change} value={UserUpdate.department} name="department" placeholder="" />
              {UserUpdateerr.department === true &&
                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
              }
              {/* <label className="label_w_s mt-2" htmlFor="a_c_i_pan">Password</label>
              <input className="search_inp_filed in_tb" type="text" onChange={handle_view_change} value={UserUpdate.password} name="password" placeholder="" />
              {UserUpdateerr.password === true &&
                <p id="username_err" className="err_inp_filed">Please Provide Valid Information For This Field</p>
              } */}
              <label className="label_w_s mt-2" htmlFor="a_c_i_pan">User Status</label>
              <select className="search_inp_filed in_tb" onChange={handle_view_change} value={UserUpdate.userStatus} name='userStatus' id="user_status">
                <option value="2">Active</option>
                <option value="1">InActive</option>
              </select>
              <label className="label_w_s mt-2" htmlFor="a_c_i_pan">User Role</label>
              <select className="search_inp_filed in_tb" onChange={handle_view_change} value={UserUpdate.userType} name='userType' id="user_search_in">
                <option value="1">Admin</option>
              </select>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={Userdetailssubmit} className="grn_btn">Save</button>
              <button type="button" data-bs-dismiss="modal" onClick={cancelusersumbit} id='model_cancel' className="btn_delete">Cancel</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="delete_hit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Alert!</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete this record?
            </div>
            <div className="modal-footer">
              <button type="button" data-bs-dismiss="modal" onClick={UserDelete} className="grd_btns">Yes</button>
              <button type="button" data-bs-dismiss="modal" id='model_cancel' className="grd_btns grey">No</button>
            </div>
          </div>
        </div>
      </div>

      <div className="h4_btm_bdr d-flex">
        <h4>USERS</h4>
        <button className="grd_btns" id='add_userdata' data-bs-toggle="modal" data-bs-target="#profile_hit">
          Add
        </button>
      </div>
      <div className="h4_btm_bdr justify-content-end d-flex align-items-center">
        <div style={{ display: 'flex', gap: '10px', width: '50%' }}>
          <select onChange={handleselectCustomerstatus} className="form-select" aria-label="Default select example" defaultValue="">
            <option value="0">Select Userstatus</option>
            <option value="1">InActive</option>
            <option value="2">Active</option>
          </select>
          <select onChange={handleselectusertype} className="form-select" aria-label="Default select example" defaultValue="">
            <option value="0">Select Usertype</option>
            <option value="1">Admin</option>
            <option value="2">User</option>
          </select>
          <input onChange={(e) => setusersearch(e.target.value)} className="search_inp_filed" type="text" name="" id="" placeholder="Search" value={usersearch}></input>
          <i onClick={handlesearchusersearch} className="fa-solid fa-magnifying-glass search_icon"></i>
        </div>
      </div>
      <div>
        <table className='table table_dv'>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>User Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {usermasterData && usermasterData.map((user, id) => (
              <tr key={id}>
                <td><p className="initial_round r1">{user.userName.charAt(0).toUpperCase()}</p></td>
                <td>{user.userName}</td>
                <td>{user.emailId}</td>
                <td>{user.userStatus === "1" ? <span>InActive</span> : <span className="">Active</span>}</td>
                <td>{user.userType === 1 ? <span>Admin</span> : <span>User</span>}</td>
                <td>
                  {user.userType === 1 && (
                    <>
                      <button onClick={() => Editusermaster(user.userName, user.emailId, user.department, user.userStatus, user.userType, user.password, user.identifier)} className="grd_btns edit">Edit</button>
                      <button onClick={() => Userdeltemodel(user.identifier)} className="user_delete">Delete</button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
          <div>
            <p style={{ color: '#000', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{usermasterDataCount}</span></p>
          </div>
          <div>
            <Pagination
              pageNumbers={usermasterNumbers}
              Paginations={userPaginations}
              handlePageChange={UserhandlePageChange}
              LastPage={usermasterDataCount && Math.ceil(usermasterDataCount / 10)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default User_master