import React, { useState } from "react";
import "./Login.css"
import CryptoJS from 'crypto-js';
import AuthService from "../Authservice";
import { useNavigate } from "react-router-dom";


const Login = () => {
    const navigate = useNavigate()
    var Head_img = require('../Header/Images/MBFSLogo.png');

    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [loginErr, setLoginErr] = useState({ usernameErr: '', userPassword: '', loginerr: false });
    // const cryptid = process.env.REACT_APP_CRYPTID

    const encryptData = (name, data) => {
        localStorage.setItem(name, data);
    };

    const Login_VRN = () => {
        if (username === '') {
            setLoginErr(prevState => ({
                ...prevState,
                usernameErr: 'Please Provide Valid Name'
            }));
        } else {
            setLoginErr(prevState => ({
                ...prevState,
                usernameErr: ''
            }));
        }
        if (password === '') {
            setLoginErr(prevState => ({
                ...prevState,
                userPassword: 'Please Provide Valid Password'
            }));
            return false
        } else {
            setLoginErr(prevState => ({
                ...prevState,
                userPassword: ''
            }));
        }
        const Login_obj = {
            "userName": username,
            "userPassword": password
        }
        if (username !== "" && password !== "") {
            AuthService.PostServiceCall('api/Login', Login_obj)
                .then((res) => {
                    if (res && res !== undefined && res !== null) {
                        console.log(res)
                        encryptData('useridentifier', res.identifier)
                        if (res.isAuthorized === true) {
                            setLoginErr(prevState => ({
                                ...prevState,
                                loginerr: false
                            }));
                            encryptData('Usertype', res.userType)
                            encryptData('JWT', res.authToken)
                            navigate('/VRN_dashboard')
                        } else {
                            setLoginErr(prevState => ({
                                ...prevState,
                                loginerr: true
                            }));
                            navigate('/Unauthorized')
                        }
                    }
                }).catch((err) => console.log("Error:", err))
        }

    };


    return (
        <div>

            <div className="container-fluid top_head_logos">
                <div className="top_img_dv">
                    <div className="hanburger_sym">
                        <img src={Head_img} alt="MBFS_LOGO" />
                    </div>
                </div>
            </div>
            <div className='container-fluid login_frm'>
                <div className="form_full_align">
                    <h3>Welcome Back</h3>
                    <div className="frm_skelton">
                        <div className="email_dv">
                            <label htmlFor="username" className="form-label">Username</label>
                            <input type="text" onChange={(e) => setusername(e.target.value)} className="form-control" id='username' value={username} />
                            <p className='login_err'>{loginErr.usernameErr}</p>
                        </div>
                        <div className="email_dv">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input type="password" onChange={(e) => setpassword(e.target.value)} className="form-control" id='password' value={password} />
                            <p className='login_err'>{loginErr.userPassword}</p>
                        </div>
                        {loginErr.loginerr === true && (
                            <p style={{ color: 'red', fontWeight: 700 }}>
                                <i className="fa-solid fa-triangle-exclamation" style={{ color: 'rgb(255, 204, 0)', marginRight: 10 }}></i>
                                Invalid User. Please try again!
                            </p>
                        )}
                        <div className="sign_btn_dv">
                            <button className="sign_btn" onClick={Login_VRN} id="btnSignIn">Sign In</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Login
