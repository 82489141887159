import React, { useEffect, useRef, useState } from 'react'
import AuthService from '../../Authservice';
import { useDispatch } from 'react-redux';
import { setPage } from '../../Redux/Slices/CustomerSlice';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

const VRN_dashboard = () => {

    const [addbatchdet, setaddbatchdet] = useState({ batchfilename: '', addbatcherrr: '', Responseerr: '', status_div: 1, loder: false, modelshow: false })
    const fileInputRef = useRef(null);
    const [statuscount, setstatuscount] = useState([])
    useEffect(() => {
        Getstatusno();
    }, [])
    const navigate = useNavigate();
    const Download_VRN_template = () => {
        AuthService.GetServiceCall('api/VRN/DownloadVRNTemplate')
            .then((res) => {
                // console.log(res)
                if (res && res !== '' && res.responseCode === "200") {
                    const href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.base64String}`;
                    const link = document.createElement('a');
                    link.href = href;
                    link.download = res.fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(link.href);
                }
            })
    }
    const Getstatusno = () => {
        AuthService.GetServiceCall('api/VRN/GetStatusdatacount')
            .then((res) => {
                console.log(res)
                setstatuscount(res.data)
            })
    }
    const handleuploadclick = () => {
        fileInputRef.current.click();
    }

    const handleFileChange = (e) => {
        const File = e.target.files[0];
        console.log('Selected file:', File);
        if (File != null) {
            setaddbatchdet(state => ({ ...state, batchfilename: File.name }));
        }
    }
    const upload_cancel = () => {
        setaddbatchdet(state => ({ ...state, batchfilename: '', addbatcherrr: '', Responseerr: '' }))
    }
    const submit_excel = () => {
        setaddbatchdet(prev => ({ ...prev, loder: true }))
        if (addbatchdet.batchfilename != '') {
            const ext = addbatchdet.batchfilename.split('.').pop();
            if (ext === 'xlsx' || ext === 'xls') {
                let fd = new FormData();
                const fileInput = document.querySelector('input[type="file"]');
                if (fileInput.files.length > 0) {
                    fd.append('file', fileInput.files[0]);
                    console.log(fd);
                    AuthService.PostServiceCallToken('api/VRN/UploadFile', fd)
                        .then((res) => {
                            console.log(res)
                            fileInputRef.current.value = null;
                            if (res !== "OK") {
                                setaddbatchdet(state => ({ ...state, batchfilename: '', addbatcherrr: '' }))
                                setaddbatchdet(state => ({ ...state, Responseerr: res }))
                                setaddbatchdet(prev => ({ ...prev, loder: false }))
                            } else {
                                upload_cancel();
                                setaddbatchdet(prev => ({ ...prev, loder: false, modelshow: true }))
                                Getstatusno();
                            }

                        })
                }
            }
        } else {
            setaddbatchdet(state => ({ ...state, addbatcherrr: 'Please Select File to Upload' }))
        }

    }
    const dispatch = useDispatch();
    const handlemasterview = (val) => {
        if (val === 2) {
            dispatch(setPage({ CustomerStatus: 2 }))
        } else if (val === 1) {
            dispatch(setPage({ CustomerStatus: 1 }))
        } else if (val === 3) {
            dispatch(setPage({ CustomerStatus: 3 }))
        } else if (val === 4) {
            dispatch(setPage({ CustomerStatus: 4 }))
        }
        navigate('/VRN_master')
    }
    return (
        <div>
            {
                addbatchdet.status_div === 1 ? (
                    <div className='statue_div'>
                        <div className='upload_dv_btn'>
                            <button className='dwn_temp mb-4' onClick={() => setaddbatchdet(prev => ({ ...prev, status_div: 2 }))}>Upload File</button>
                        </div>
                        <div className='status_div_in'>
                            <div onClick={() => handlemasterview(2)}>
                                <p>Pending</p>
                                <p>{statuscount && statuscount.pendingcount}</p>
                            </div>
                            <div onClick={() => handlemasterview(3)}>
                                <p>Approved</p>
                                <p>{statuscount && statuscount.approvedcount}</p>
                            </div>
                            <div onClick={() => handlemasterview(4)}>
                                <p>Rejected</p>
                                <p>{statuscount && statuscount.rejectcount}</p>
                            </div>
                        </div>
                        <div className='status_div_tw'>
                            <div onClick={() => handlemasterview(1)}>
                                <p>Pending For Approval</p>
                                <p>{statuscount && statuscount.pendingapprovelcount}</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='VRN_master'>
                        <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                        <div className='mb-3' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '35%' }}>
                            <button onClick={Download_VRN_template} className='dwn_temp'>Download Template</button>
                            <i onClick={() => setaddbatchdet(prev => ({ ...prev, status_div: 1 }))} style={{ fontSize: '30px', cursor: 'pointer' }} className="fa-regular fa-circle-xmark"></i>
                        </div>
                        <div className='vrn_upload'>
                            <div>
                                <h3>VRN Upload</h3>
                            </div>
                            <div className='text-center mt-3'>
                                <button onClick={handleuploadclick} className='upload_bnt'><span><i className="fa-solid fa-upload"></i></span>Upload</button>
                                <p style={{ color: '#000' }} className="req_txt batch_filename">{addbatchdet.batchfilename}</p>
                                <p className="float_txt" id="invalidText">{addbatchdet.addbatcherrr}</p>
                            </div>
                            <div className='btn_down'>
                                {
                                    addbatchdet.loder === true ? (
                                        <>
                                            <div className="spinner-border spiner" role="status">
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={submit_excel}>Submit</button>
                                            <button onClick={upload_cancel}>Cancel</button>
                                        </>
                                    )
                                }
                            </div>
                            <p className='if_empty'>{addbatchdet.Responseerr}</p>
                        </div>
                    </div>
                )
            }
            <Modal show={addbatchdet.modelshow}>
                <Modal.Header>
                    <Modal.Title>Alert!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Uploaded SuccessFully</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setaddbatchdet(prev => ({ ...prev, modelshow: false }))}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default VRN_dashboard