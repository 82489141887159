import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../../Authservice";

export const FetchCustomermaindata = createAsyncThunk('FetchCustomermaindata', async (Pannumber, { getState, rejectvalue }) => {

    try {
        //console.log('coming')
        const { customermainpagination } = getState().customermain;
        const customermaster = {
            startPage: customermainpagination.Current_page,
            noofPages: 0,
            search: '',
            CustomerStatus: 0,
            CustomerType: 0,
            Panumber:Pannumber
        };
        //console.log('yes')
        const response = await AuthService.PostServiceCallToken('api/Customer/GetCustomermaindetails', customermaster);
        //console.log(response)
        return { data: response.data, recordCount: response.recordCount };
    } catch (error) {
        return rejectvalue(error);
    }
});



const initialState = {
    CustomermainDate: [],
    CustomermainDateCount: 0,
    customermainpagination: {
        Current_page: 1,
        noofPages: 0,
    },
    loading: false,
    error: null,
};

const customermainSlice = createSlice({
    name: 'customermain',
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.customermainpagination.Current_page = action.payload.Current_page || state.customermainpagination.Current_page;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(FetchCustomermaindata.pending, (state) => {
                state.loading = true;
            })
            .addCase(FetchCustomermaindata.fulfilled, (state, action) => {
                state.loading = false;
                state.CustomermainDate = action.payload.data;
                state.CustomermainDateCount = action.payload.recordCount;
            })
            .addCase(FetchCustomermaindata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.CustomermainDate = [];
            });
    },
});

export const { setPage } = customermainSlice.actions;
export default customermainSlice.reducer;