import React, { useEffect, useState } from 'react'
import '../SSO_Login/SSO_style.css'

const RedirectLoader = () => {

    const [dots, setDots] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prevDots) => (prevDots < 3 ? prevDots + 1 : 1));
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="loader-container">
            <div className="loader"></div>
            <p className="loading-text"><strong>Loading{Array(dots).fill('.').join('')}</strong></p>
        </div>
    )
}

export default RedirectLoader