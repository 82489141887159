import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import AuthService from '../../Authservice';
import { Button, Modal } from 'react-bootstrap';

const Customer_Password_change = () => {
    const [password, setpassword] = useState({ newpassword: '', changepassword: '', Pannumber: '' })
    const [passerr, setpassworderr] = useState({ newerrr: '', cnfrmerr: '', pannerr: '', sameerr: '', passwordvalidateerr: '', modelshow: false })
    const [Getpannumber, setgGetpannumber] = useState('')
    var Head_img = require('../../Header/Images/MBFSLogo.png');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const qids = queryParams.get('identifier');
    const navigate = useNavigate();
    console.log(qids)
    const validatePAN = (panNumber) => {
        const panRegex = /^[A-Za-z]{5}\d{4}[A-Za-z]$/;
        const isValidPAN = panRegex.test(panNumber);
        return isValidPAN
    };
    const passwordvalidation = (password) => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{10,}$/;
        const isvalidpassword = passwordPattern.test(password)
        return isvalidpassword
    }
    useEffect(() => {
        DoGetPannumberbyidentifier();
    }, [])
    const DoGetPannumberbyidentifier = () => {
        AuthService.GetServiceCall(`api/Login/DoGetPannumberbyidentifier?identifier=${qids}`)
            .then((res) => {
                if (res) {
                    setgGetpannumber(res)
                }
            })
    }
    const Set_password = () => {
        const validpassworderr = passwordvalidation(password.newpassword)
        console.log(validpassworderr)
        if (password.newpassword === '') {
            setpassworderr(prevState => ({
                ...prevState,
                newerrr: 'Please Provide Valid New Password'
            }));
        } else {
            setpassworderr(prevState => ({
                ...prevState,
                newerrr: ''
            }));
        }

        if (password.changepassword === '') {
            setpassworderr(prevState => ({
                ...prevState,
                cnfrmerr: 'Please Provide Valid New Password'
            }));
        } else {
            setpassworderr(prevState => ({
                ...prevState,
                cnfrmerr: ''
            }));
        }
        if (password.newpassword.length > 0) {
            if (!validpassworderr) {
                setpassworderr(prevState => ({
                    ...prevState,
                    newerrr: 'The password must contain at least one uppercase letter, one digit, and one special character.'
                }));
            } else {
                setpassworderr(prevState => ({
                    ...prevState,
                    newerrr: ''
                }));
            }
        }
        if (password.newpassword.length > 0 && password.changepassword.length > 0) {
            if (password.changepassword !== password.newpassword) {
                setpassworderr(prevState => ({
                    ...prevState,
                    sameerr: 'Please Provide Same Password For New Password and Confirm Password'
                }));
            } else {
                setpassworderr(prevState => ({
                    ...prevState,
                    sameerr: ''
                }));
            }
        }
        const obj = {
            UserPassword: password.changepassword,
            Pannumber: Getpannumber,
            UserName: ''
        }
        if (password.changepassword !== "" && password.newpassword !== "" && validpassworderr && (password.changepassword === password.newpassword)) {
            AuthService.PostServiceCallToken('api/Login/Dosetpassword', obj)
                .then((res) => {
                    console.log(res)
                    if (res && res === 'Success') {
                        navigate('/customer_login')
                    } else if ('Time IS Expired') {
                        setpassworderr(prev => ({ ...prev, modelshow: true }))
                    }
                })
        }

    }
    return (
        <div>
            <div className="container-fluid top_head_logos">
                <div className="top_img_dv">
                    <div className="hanburger_sym">
                        <img src={Head_img} alt="MBFS_LOGO" />
                    </div>
                </div>
            </div>
            <div className='container-fluid login_frm'>
                <div className="form_full_align">
                    <h3>Password Change</h3>
                    <div className="frm_skelton">
                        <div className="email_dv">
                            <label htmlFor="username" className="form-label">New Password</label>
                            <input type="password" onChange={(e) => setpassword(prev => ({ ...prev, newpassword: e.target.value }))} className="form-control" id='username' value={password.newpassword} />
                            <p className='login_err'>{passerr.newerrr}</p>
                            <p className='login_err'>{passerr.passwordvalidateerr}</p>
                        </div>
                        <div className="email_dv">
                            <label htmlFor="password" className="form-label">Confirm Password</label>
                            <input type="password" onChange={(e) => setpassword(prev => ({ ...prev, changepassword: e.target.value }))} className="form-control" id='password' value={password.changepassword} />
                            <p className='login_err'>{passerr.cnfrmerr}</p>
                        </div>
                        <div>
                            <p className='login_err'>{passerr.sameerr}</p>
                        </div>
                        <div className="sign_btn_dv">
                            <button className="sign_btn" onClick={Set_password} id="btnSignIn">Set Password</button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={passerr.modelshow}>
                <Modal.Header>
                    <Modal.Title>Alert!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Time is Expired</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            navigate('/customer_login');
                            setpassworderr(prev => ({ ...prev, modelshow: true }));
                        }}
                    >
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Customer_Password_change