// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-fluid.top_head_logos {
    background-color: #040707;
}
.top_img_dv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.container-fluid.top_head_logos img {
    width: 250px;
    padding: 10px 0px;
}
.container-fluid.login_frm {
    background-color: #e9e9e9;
}
.container-fluid.login_frm {
    background-color: #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96vh;
}
.form_full_align h3 {
    color: #000;
    margin-bottom: 25px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: center;
}
.frm_skelton {
    width: 380px;
    padding: 40px 30px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.email_dv {
    margin-bottom: 30px;
}
button.sign_btn {
    width: 100%;
    color: #fff;
    background: #040707;
    border: none;
    font-weight: 600;
    padding: 7px 0px;
    border-radius: 5px;
    margin-top: 10px;
}
p.login_err {
    font-size: 13px;
    color: red;
    margin-bottom: 0;
    margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;IACtB,8EAA8E;AAClF;AACA;IACI,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,UAAU;IACV,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".container-fluid.top_head_logos {\n    background-color: #040707;\n}\n.top_img_dv {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n.container-fluid.top_head_logos img {\n    width: 250px;\n    padding: 10px 0px;\n}\n.container-fluid.login_frm {\n    background-color: #e9e9e9;\n}\n.container-fluid.login_frm {\n    background-color: #e9e9e9;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 96vh;\n}\n.form_full_align h3 {\n    color: #000;\n    margin-bottom: 25px;\n    font-weight: 600;\n    letter-spacing: 0.5px;\n    text-align: center;\n}\n.frm_skelton {\n    width: 380px;\n    padding: 40px 30px;\n    border-radius: 10px;\n    background-color: #fff;\n    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;\n}\n.email_dv {\n    margin-bottom: 30px;\n}\nbutton.sign_btn {\n    width: 100%;\n    color: #fff;\n    background: #040707;\n    border: none;\n    font-weight: 600;\n    padding: 7px 0px;\n    border-radius: 5px;\n    margin-top: 10px;\n}\np.login_err {\n    font-size: 13px;\n    color: red;\n    margin-bottom: 0;\n    margin-top: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
