import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../../Authservice";

export const FetchCustomerdata = createAsyncThunk('FetchCustomerdata', async (_, { getState, rejectvalue }) => {

    try {
        const { customerpagination } = getState().customer;
        const customermaster = {
            startPage: customerpagination.Current_page,
            noofPages: 0,
            search: customerpagination.search,
            CustomerStatus:customerpagination.CustomerStatus,
            CustomerType:customerpagination.CustomerType
        };
        const response = await AuthService.PostServiceCallToken('api/VRN/GetCustomerdetails', customermaster);
        console.log(response)
        return { data: response.data, recordCount: response.recordCount };
    } catch (error) {
        return rejectvalue(error);
    }
});

const initialState = {
    CustomerDate: [],
    CustomerDateCount: 0,
    customerpagination: {
        Current_page: 1,
        noofPages: 0,
        search: '',
        CustomerStatus:0,
        CustomerType:0
    },
    loading: false,
    error: null,
};

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.customerpagination.Current_page = action.payload.Current_page || state.customerpagination.Current_page;
            state.customerpagination.CustomerStatus = action.payload.CustomerStatus || state.customerpagination.CustomerStatus;
            state.customerpagination.CustomerType = action.payload.CustomerType || state.customerpagination.CustomerType;
            state.customerpagination.search = action.payload.search !== undefined ? action.payload.search : state.customerpagination.search;
            console.log('Updated State:', state.customerpagination);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(FetchCustomerdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(FetchCustomerdata.fulfilled, (state, action) => {
                state.loading = false;
                state.CustomerDate = action.payload.data;
                state.CustomerDateCount = action.payload.recordCount;
            })
            .addCase(FetchCustomerdata.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.CustomerDate = [];
            });
    },
});

export const { setPage } = customerSlice.actions;
export default customerSlice.reducer;